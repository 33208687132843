import request from "@/utils/request";

export function getInfo(params) {
  return request({
    url: "/h5/info",
    method: "get",
    params: params,
  });
}

export function getJob(params) {
  return request({
    url: "/h5/jobPost",
    method: "get",
    params: params,
  });
}

export function getInterview(params) {
  return request({
    url: "/h5/interview",
    method: "get",
    params: params,
  });
}
